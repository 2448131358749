import IMG1 from "../../assets/hackwithinfy.png";
import IMG2 from "../../assets/devbhumicyberhackathon.png";
import IMG3 from "../../assets/sih.png";
import IMG4 from "../../assets/google.jpg";
import IMG5 from "../../assets/TCS_LOGO.jpeg";
import IMG8 from "../../assets/codegeist.png";
import IMG7 from "../../assets/cyientifiq.png";
import IMG6 from "../../assets/ey_techathon.png";
import IMG9 from "../../assets/icon_hyperbuild.png";
import IMG10 from "../../assets/devfest_eket.png";

export const hackathondata = [
  {
    id: 1,
    company: "HACK WITH INFY",
    cover: IMG1,
    about: (
      <div>
        <b>COMMENCEMENT AND DURATION OF THE HACKATHON</b>
        <br />
        <br />
        The HackWithInfy will be conducted in two rounds:
        <br />
        Round 1: March 6,7,and 8, 2022
        <br />
        An online individual participation round on Infosys Assessment Platform.
        <br />
        Final Round: July 1-July 4, 2022
        <br />
        The 48-hour hackathon among teams and the Grand Finale.
      </div>
    ),
    problem: (
      <div>
        <li>→ Lack or an insufficient number of experts and consultants</li>
        <li>→ Venue and technical equipment</li>
        <li>→ Clearconditions, deadlines, chat</li>
        <li>→ Communication Gap</li>
        <li>→ Lack of Confidence</li>
        <li>→ Lack of Evaluation</li>
      </div>
    ),
    process: (
      <div>
        <b>Syllabus:</b>
        <br />
        <br />
        <p>
          However, round 1 and round 2 consists of 3 coding problems, and the
          questions are based on these key topics -
        </p>
        <br />
        <ul>
          <li>
            •<i> Dynamic Programming</i> - An algorithm technique to solve an
            optimization problem.
          </li>
          <li>
            • <i>Backtracking</i> - Building a solution incrementally for
            computational problems.
          </li>
          <li>
            • <i>Mapping Concepts</i> - The process of matching fields and a
            visual representation of the information gathered.
          </li>
          <li>
            • <i>String Manipulation</i> - Changing, slicing, pasting, and even
            an analysis of strings.
          </li>
          <li>
            • <i>Tree</i> - Used to store data with an inherent hierarchical
            structure.
          </li>
          <li>
            • <i>Graph</i> - Non-linear data structure used to solve complex
            issues.
          </li>
          <li>
            • <i>Greedy Algorithms</i> - Simple and intuitive program for
            optimization problems.
          </li>
        </ul>
        <br />
        <br />
        <b>Eligibility Criteria:</b>
        <br />
        <br />
        <ul>
          <li>• 18 years or above</li>
          <li>• Citizen of India</li>
          <li>
            • B.E. / B.Tech / M.E. / M.Tech graduates in computer science or
            Information Technology.
          </li>
          <li>
            • The percentage should be 60% or 6.5 CGPA above in all over
            education 10th, 12th, UG, or PG.
          </li>
          <li>• TNo Active Backlogs.</li>
        </ul>
        <br />
        <br />
        <ul>
          <li>
            <b>Round 1:</b>
            <br />
            Individual online participation on the Infosys Assessment Platform.
            This round will be hosted on the Infosys Assessment Platform (IAP).
            <br />
            • Total Number of Questions -3
            <br />
            • Total Time Limit- 3 Hour
            <br />• Difficulty Level- Medium-High.
          </li>
          <br />
          <li>
            <b>Round 2:</b>
            <br />
            The 48-hour hackathon among teams and the Grand Finale hosted on
            Infosys Meridian.
            <br />
            The top 100 participants from Round 1 will compete in the four-day
            virtual grand finale in July 2021.
            <br />
          </li>
          <br />
        </ul>
      </div>
    ),
    judgingCriteria: (
      <div>
        <ul>
          <li>→ Code Efficiency:- How many number of test cases get pass</li>
          <li>
            → Marks are assigned on the basis of difficulty level and solutions
            evaluated accordingly.
          </li>
          <li>
            → Plagiarism is not encouraged and there shall be negative marking
            for plagiarism.
          </li>
        </ul>
      </div>
    ),
    reward: (
      <div>
        <ul>
          <li>
            • Top performers will get prize money of INR 200000, INR 100000, and
            INR 50000.
          </li>
          <li>
            • Good rankers will get job full-time job opportunities and
            internship opportunities with Infosys.
          </li>
          <li>
            • Power programmer salary would be around 9,50,000/- per annum.
          </li>
          <li>
            •Digital System Engineer salary would be around 6,25,198/- per
            annum.
          </li>
          <li>
            • System Engineer Specialist salary would be around 3,60,198/- per
            annum.
          </li>
        </ul>
      </div>
    ),
  },
  {
    id: 2,
    company: "DEVBHOOMI CYBER HACKATHON",
    cover: IMG2,
    about: (
      <div>
        <b>COMMENCEMENT AND DURATION OF THE HACKATHON</b>
        <br />
        <br />
        The Devbhoomi Cyber Hackathon is a skill contest. It consists of two
        rounds, a preliminary round to be held in May, June, July 2022, followed
        by declaration of selected teams by the end of July for the final round,
        scheduled for 09.08.2022 to 12.08.2022.
      </div>
    ),
    problem: (
      <div>
        <li>→ Crime Mapping</li>
        <li>→ Drone Technology</li>
        <li>→ Video Analytics</li>
        <li>→ Crypto Currency</li>
        <li>→ Traffic Management</li>
        <li>→ Safe Travel</li>
        <li>→ Network Forensics</li>
        <li>→ Narcotics Detectors</li>
        <li>→ Layering of Bank accounts</li>
      </div>
    ),
    process: (
      <div>
        <b>Eligibility Criteria:</b>
        <br />
        <br />
        <ul>
          <li>
            • The participants will have to abide by the official rules provided
            by Uttarakhand Police for participation from time-to-time including
            the rules mentioned below. There is no entrance fee for
            participation.
          </li>
          <li>• 18 years or above</li>
          <li>• Citizen of India</li>
          <li>• Verified government ID or</li>
          <li>• Verified college ID or institute/organisation.</li>
          <li>
            • The participation in the event will be in the form of a single-
            member (individual) team or a group consisting up to a maximum of 5
            members.
          </li>
        </ul>
        <br />
        <br />
        <ul>
          <li>
            <b>Prelims Round:</b>
            <br />
            • Source code to have been developed during the course of the event.
            <br />
            • Source code not to contain any element with patent or copyright
            beforehand.
            <br />
            • Source code/program to be submitted through the official hackathon
            mail ID where the participants can mail with solution link or
            forward in google drive.
            <br />• If the participant fails to qualify for the final round,
            he/she can revoke the permission granted to access the solution.
          </li>
          <br />
          <li>
            <b>Final Round:</b>
            <br />
            • The selected teams will be invited for the final event scheduled
            between 09.08.2022-12.08.2022. The constitution of the team selected
            for the main event will not change and will have the same membership
            as the preliminary round.
            <br />
            • At the beginning of the event, the problem statements will be
            shared with all participants at the same time. The main event will
            take place in offline mode at the Indian Institute of Technology,
            Roorkee. The final round will be a 48 hours long session beginning
            on 09.08.2022 and ending on 10.08.2022. The Jury will judge the main
            event on 11.08.22 while the felicitation ceremony is slated for
            12.08.22.
            <br />
          </li>
          <br />
        </ul>
      </div>
    ),
    judgingCriteria: (
      <div>
        <ul>
          <li>→ Relevancy</li>
          <li>→ Novelty of concept</li>
          <li>→ Practicality, feasibility and sustainability</li>
          <li>→ Meeting the expectation of the police.</li>
        </ul>
      </div>
    ),
    reward: (
      <div>
        <ul>
          <li>
            • The declaration of the winner based on the above criteria will be
            a matter of pure discretion of the panel and not open to questioning
            or scrutiny. The winner will be declared on 12.08.2022 as per the
            timeframe of the committee.
          </li>
          <li>
            • The top three teams will be awarded motivational prizes along with
            a certificate of excellence for all the participants of the main
            event. The reward will be given to the participants directly by the
            sponsors.
          </li>
        </ul>
      </div>
    ),
  },
  {
    id: 3,
    company: "SMART INDIA HACKATHON",
    cover: IMG3,
    about: (
      <div>
        <b>COMMENCEMENT AND DURATION OF THE HACKATHON</b>
        <br />
        <br />
        Smart India Hackathon is a nationwide 36-hour Hackathon organized by the
        MHRD, Govt. of India. It started in the year 2017 and gaining huge
        success, it is now an annual event with SIH 2020 being the 4th edition.
        It is the World’s Biggest Hackathon having reached out to over 10 Lakh+
        students across 7218+ institutes in India.
      </div>
    ),
    problem: <div></div>,
    process: (
      <div>
        <b>Process Flow:</b>
        <br />
        <br />
        <ul>
          <li>
            <i>Step 1</i> - SIH2022 Software & Hardware Edition: Problem
            statements submission by Central &State Ministries, Departments,
            PSUs, Industries & NGOs.
          </li>
          <li>
            <i>Step 2</i> - Submission of Problem Statements (PSs) by Central &
            State Ministries, PSUs, Industries and NGOs on the SIH portal.
            Review of Submitted PSs & finalization by concerned committee
            publishing finalized PSs
          </li>
          <li>
            <i>Step 3</i> - Registration of College SPOCs on SIH portal blocking
            published PSs by SPOCs of Colleges or Institutes Internal campus
            hackathon to be conducted in each college or institute by their
            SPOCs on their blocked PSs.
          </li>
          <li>
            <i>Step 4</i> - College SPOC to be responsible to conduct internal
            hackathon in the Campus College to finalise total best 10 teams + 5
            waitlisted teams from the internal hackathon. SPOC to recommend the
            selected teams for SIH 2022 Software/Hardware edition at SIH portal.
          </li>
          <li>
            <i>Step 5</i> - Team leaders of the selected teams to be registered
            at the SIH portal to submit ideas Submission of ideas by selected
            teams against PSs by college SPOC for the SIH2022 Finale Evaluation
            of submitted ideas
          </li>
          <li>
            <i>Step 6</i> - Result Analysis and Result Publication.
          </li>
        </ul>
        <br />
        <br />

        <ul>
          <li>
            <b>Registration::</b>
            <br />
            ● The first step is to get a faculty member from the college
            registered as the SPOC.
            <br />
            The students selected in the Internal Hackathon can be registered on
            the SIH portal by their college SPOC only. Students cannot directly
            register themselves.
          </li>
          <br />
          <li>
            <b>Team Formation:</b>
            <br />
            ● A team of 6 members with one team leader.
            <br />
            ● At least one female team member.
            <br />
            ● All students should be from the same college. Inter-college teams
            are not allowed.
            <br />
            ● Students from different branches of the same college are
            encouraged to form a team.
            <br />● Team Name should be unique and not contain the name of their
            institute in any form
          </li>
          <br />
          <br />
          <li>
            <b>Internal Hackathon:</b>
            <br />
            ● In the 2020 edition, to ensure the best teams from a college get
            selected, an Internal Hackathon was conducted in the first week of
            February 2020 within the college campus.
            <br />
            ● The SPOC along with another Jury member finalized a total of 5
            best teams from this internal hackathon.
            <br />
            ● These 5 shortlisted teams from each college were selected to
            participate in SIH 2020 Hackathon – Software Edition.
            <br />
            ● For the selected teams, 4 problem statements can be from the ones
            listed on the portal & 1 can be chosen for the Student Innovation
            category.
            <br />
            ● This internal Hackathon had to be documented and details like
            details of the Judges and few photos, videos of the event had to be
            submitted along with the submission of ideas.
            <br />
          </li>
          <br />
          <li>
            <b>Submission of Ideas:</b>
            <br />
            The SPOC then register the teams selected from the Internal
            Hackathon on the SIH portal along with the following details:
            <br />
            ● Team Name
            <br />
            ● Name, gender, Email id, Mobile No. of team leader and all other
            Members
            <br />
            ● Chosen Problem Statement and its proposed solution with a title,
            description & presentation PDF.
            <br />
            ● College authorization letter
            <br />
            This registration process was open till 20 February 2020.
            <br />
            The names of the teams selected for the grand finale of the Software
            Edition were announced in the 1st week of March.
            <br />
          </li>
        </ul>
        <br />
        <b>Selection Criteria:</b>
        <br />
        <ul>
          <li>
            The proposed solutions are evaluated by experts on points like the
            novelty of the idea, complexity, clarity, detailed document,
            feasibility, practicability, and sustainability, the scale of
            impact, user experience, and potential for future work progression.
            <br />
            <br />
            Refer:{" "}
            <a
              className="btn-creative"
              href="https://www.sih.gov.in/pdf/IdeasubmissionprocessSIH2020.pdf"
              alt=""
              target={"_blank"}
            >
              Download PDF
            </a>
          </li>
        </ul>
        <br />
        <b>Grand Finale:</b>
        <br />
        <ul>
          <li>
            All the teams whose name appeared in this list qualified to
            participate in the Grand Finale.
            <br />
            <br />
            <i>Nodal Centers:</i>
            <br />
            ● Usually, the Grand Finale is conducted at various Nodal Centres
            (colleges and universities) across India and the teams need to
            travel to the assigned Nodal Centre
            <br />
            ● Each nodal Centre works with a few ministries/organization and
            their proposed problem statements are managed by that nodal Center.
            <br />
            ● They take care of the logistics, lodging, boarding, and are in
            contact with the team leaders, providing all the necessary details
            from time to time.
            <br />
            ● The shortlisted teams are also eligible to receive reimbursement
            for to and from journey via 2S (2nd Class sleeper) railway fare.
            <br />
            But, due to the 2020 pandemic, all the teams were asked to
            participate from their native places and the nodal centers had the
            responsibility to conduct the event completely online bringing all
            the stakeholders together on one common platform (for e.g. Slack)
            and conduct the required meetings via web conferencing.
          </li>
        </ul>
        <br />
        <b>Benifits:</b>
        <br />
        <br />
        <ul>
          <li>
            ● It gives you an opportunity to brand your organization nationally.
          </li>
          <li>
            ● It gives recognition and visibility for your organization across
            all technical institutions in India.
          </li>
          <li>
            ● The young techies from all over the country offer out-of-the-box
            solutions to your problems.
          </li>
          <li>
            ● It provides an opportunity to be a part of world’s biggest open
            innovation movement.
          </li>
          <li>
            ● It gives an opportunity to work with some of the best talent in
            the country.
          </li>
          <li>
            ● Technology students across India compete to creatively solve
            problems and offer technical solutions.
          </li>
          <li>
            ● Harness expertise of lakhs of students from IISc, IITs, NITs and
            AICTE/UGC approved institutions.
          </li>
          <li>
            ● Crowdsource solutions for improving governance and quality of
            life.
          </li>
          <li>
            ● Provide opportunity to citizens to provide innovative solutions to
            India's daunting problems.
          </li>
          <li>● Certificates are provided by the organiser.</li>
          <li>
            ● Opportunity to interact/internship/placement during competition by
            industrial expert’s.
          </li>
          <li>
            ● Travelling cost, accommodation, Breakfast, lunch and dinner during
            competition will be paid by{" "}
          </li>
          organiser.
          <li>● The team will also be awarded with a cash prize of 1 lakh.</li>
        </ul>
      </div>
    ),
    judgingCriteria: (
      <div>
        <ul>
          <li>→ Technical difficulty and complexity (30%)</li>
          <li>→ Innovation (20%)</li>
          <li>→ Impact and sustainability (20%)</li>
          <li>→ User experience (15%)</li>
          <li>→ Presentation and communication (15%)</li>
        </ul>
      </div>
    ),
    reward: (
      <div>
        <ul>
          <li>
            ● One winning team for every problem statement posted on the portal.
          </li>
          <li>
            ● A winner would be selected and given the prize money only if the
            organization likes their idea. If they don’t like any idea they may
            not announce any winner.
          </li>
          <li>
            ● Prize money for the winning team is Rs. 100000/- per problem
            statement.
          </li>
          <li>
            ● In case of a tie, the final decision on prize money distribution
            will be taken by the problem statement creator alone.
          </li>
          <li>● Once a decision is made, it won’t be changed further.</li>
          <li>
            ● For the Student Innovation category, there are 3-4 nodal centers
            and 3 teams from each center selected:
            <ul>
              <li>● 1st prize: Rs. 100000 /-</li>
              <li>● 2nd prize: Rs. 75000 /-</li>
              <li>● 3rd prize: Rs. 50000 /-</li>
            </ul>
          </li>
        </ul>
      </div>
    ),
  },
  {
    id: 4,
    company: "GOOGLE HACKATHON",
    cover: IMG4,
    about: (
      <div>
        <b>COMMENCEMENT AND DURATION OF THE HACKATHON</b>
        <br />
        <br />
        Google has announced its Girl Hackathon. This program will run from
        March 19 to April 30, 2022. Google India is currently accepting
        applications from ‘women coders’ to participate in the Girl Hackathon
        2022.
      </div>
    ),
    problem: (
      <div>
        <li>
          → Lack or an insufficient number of experts and consultants Sources
          and data
        </li>
        <li>→ Venue and technical equipment</li>
        <li>→ Clear conditions, deadlines, chat</li>
        <li>→ Communication Gap</li>
        <li>→ Lack of Confidence</li>
        <li>→ Lack of Evaluation</li>
      </div>
    ),
    process: (
      <div>
        <b>Eligibility Criteria:</b>
        <br />
        <br />
        <ul>
          <li>
            • The participants will have to abide by the official rules provided
            by Uttarakhand Police for participation from time-to-time including
            the rules mentioned below. There is no entrance fee for
            participation.
          </li>
          <li>• 18 years or above</li>
          <li>• Citizen of India</li>
          <li>• Verified government ID or</li>
          <li>• Verified college ID or institute/organisation.</li>
          <li>
            • The participation in the event will be in the form of a single-
            member (individual) team or a group consisting up to a maximum of 5
            members.
          </li>
        </ul>
        <br />
        <br />
        <ul>
          <li>
            <b>Round one: Google Online Challenge (GOC):</b>
            <br />• All participants will be required to participate in the
            Google Online Challenge. This is a coding challenge that will assess
            participants online in the areas of coding/puzzles. The GOC will
            take place for 60 min (between 2:00 p.m. to 6:00 p.m. IST) and all
            team members will be required to participate. Teams will be judged
            on a group average and will proceed to the next round based on group
            performance. The GOC will take place on March 19, 2022. The results
            for GOC and the groups advancing to the next round will be announced
            on March 24, 2022.
          </li>
          <br />
          <li>
            <b>Round two: Design document round</b>
            <br />
            • The design document round consists of a tech theme/challenge that
            requires innovative solutions. All participant groups will have the
            same theme to ensure fairness.
            <br />
            • The theme for the hackathon is Future of Learning.
            <br />
            • If you have advanced from the GOC round, a design document sample
            with the theme will be shared with you. Please make a copy of the
            document and use the same to build your pitch. The document will be
            shared on March 24, 2022.
            <br />
            • Please do not use this document to enter code. You will have the
            next round to showcase the same.
            <br />
            • Teams may not change or alter their solutions once submitted.
            <br />
            • Only one submission per team is allowed.
            <br />
            • Design Document Submissions will close on April 7, 2022.
            <br />• Results of the design document round will be announced on
            April 13, 2022 with a guideline to the final hackathon round.
          </li>
          <br />
          <br />
          <li>
            <b>Round three: Virtual hackathon (national finale):</b>
            <br />
            • The virtual hackathon round will enable the final teams to present
            their final solutions to a panel of judges.
            <br />
            • Teams will need to share a working demo/prototype and code
            repository on April 22, 2022.
            <br />
            • The hackathon round will challenge your coding, user experience
            and innovative skills. The round will take place on April 27, 2022.
            <br />
            • Final teams will be required to present their solutions for 15 min
            each only - 8 min for presentation and 7 min for Q&A.
            <br />
            • Final winners and runners Up from Group A and Group B will be
            shared on April 27, 2022.
            <br />
          </li>
          <br />
        </ul>
      </div>
    ),
    judgingCriteria: (
      <div>
        <ul>
          <li>→ Technical difficulty and complexity (30%)</li>
          <li>→ Innovation (20%)</li>
          <li>→ Impact and sustainability (20%)</li>
          <li>→ User experience (15%)</li>
          <li>→ Presentation and communication (15%)</li>
        </ul>
      </div>
    ),
    reward: (
      <div>
        <ul>
          <li>
            • The declaration of the winner based on the above criteria will be
            a matter of pure discretion of the panel and not open to questioning
            or scrutiny. The winner will be declared on 12.08.2022 as per the
            timeframe of the committee.
          </li>
          <li>
            • The top three teams will be awarded motivational prizes along with
            a certificate of excellence for all the participants of the main
            event. The reward will be given to the participants directly by the
            sponsors.
          </li>
        </ul>
      </div>
    ),
  },
  {
    id: 5,
    company: "TCS CODEVITA",
    cover: IMG5,
    about: (
      <div>
        <b> CodeVita: The TCS Global Coding Contest for Students</b>
        <br />
        <br />
        It connects people from varied backgrounds and ethnicities regardless of
        physical and cultural boundaries. It's also an exhilarating way to
        compete beyond borders and grow more skills as a programmer. At TCS, we
        firmly believe in this philosophy that programming can be both fun and
        challenging, which led to the inception of "CodeVita".
      </div>
    ),
    // problem: (
    //   <div>
    //     <li>
    //     What's in it for students?
    //     </li>
    //     <li>→ Global ranking to top coders</li>
    //     <li>→ Top 3 coders to win total a prize money of USD 20,000/-</li>
    //     <li>→ A chance to explore exciting careers* with one of the world's most powerful brand</li>
    //     <li>→ A chance to compete with some of the best coders in the world</li>
    //     <li>→ A platform to showcase your programming skills</li>
    //     <li>→ The finalists stand a chance to travel** to India for the live grand finale experience.</li>
    //   </div>
    // ),
    process: (
      <div>
        <h2> How do I register and login for the contest?</h2>
        <p>
          Registration process is common for India and Global participants.
          Steps are as enumerated below:
        </p>
        <br />
        <br />
        <li>
          • Click on the Login button at the top right-hand side of the page
        </li>
        <li>
          • After clicking on it, a dialog box will pop-up with the Registration
          link
        </li>
        <li>• Click on the Registration link</li>
        <li>• Then choose India or Rest of the World, as applicable</li>
        <li>
          • Do read the Privacy notice, fill the registration details, and submit
          the form
        </li>
        <li>
          • After that, accept Terms and Conditions on the next page and click on
          the Register button
        </li>
        <li>
          • A message will appear saying that an email has been sent to you for
          verification
        </li>
        <li>
          • Open your mailbox, and click on the link given in the email to verify
          your email address
        </li>
        <li>
          • The verification link is valid for a short duration, so complete the
          email verification quickly
        </li>
        <li>
          • If clicking on the email link shows a success message, it means your
          verification and registration is successful
        </li>
        <li>
          • You can now login using your registered email id and chosen password
          to CodeVita website
        </li>
        <br />
        <br />
        <b> Round 1(Pre-Qualifier):</b>
        <br />
        <br />
        <ul>
          <li>• You have a 6 hours online coding challenge.</li>
          <li>
            • It should be complete within 24-hours at your comfort times.
          </li>
          <li>
            • If you qualified this round with a good rank there may be high
            chances to get an interview with TCS.
          </li>
        </ul>
        <br />
        <br />
        <ul>
          <li>
            <b>Round 2 (Qualifier):</b>
            <br />• Shortlisted students from Round 1 will get a chance to
            attend Round 2.
            <br />• You will get 6 hours to complete questions but this time the
            questions will be more difficult than round 1.
            <br />• The benefit of getting a good rank in this round is a good
            hike and a chance to get allocated in the latest technology project.
          </li>
          <br />
          <li>
            <b>Round 3 (Finale):</b>
            <br />
            • You will get an invitation to one of any TCS Campus for this round
            and participants who are from another country out of India their
            expenses are held by TCS.
            <br />• Top 3 Rankers will get a chance to win USD 20,000.
          </li>
          <br />
          <br />
        </ul>
      </div>
    ),
    judgingCriteria: (
      <div>
        <ul>
          <li>→ Technical difficulty and complexity (30%)</li>
          <li>→ Innovation (20%)</li>
          <li>→ Impact and sustainability (20%)</li>
          <li>→ User experience (15%)</li>
          <li>→ Presentation and communication (15%)</li>
        </ul>
      </div>
    ),
    reward: (
      <div>
        <li>→ Global ranking to top coders</li>
        <li>→ Top 3 coders to win total a prize money of USD 20,000/-</li>
        <li>
          → A chance to explore exciting careers* with one of the world's most
          powerful brand
        </li>
        <li>→ A chance to compete with some of the best coders in the world</li>
        <li>→ A platform to showcase your programming skills</li>
        <li>
          → The finalists stand a chance to travel** to India for the live grand
          finale experience.
        </li>
      </div>
    ),
  },
  {
    id: 6,
    company: "EY TECHATHON",
    cover: IMG6,
    about: (
      <div>
        <b> EY Techathon 4.0 | Re-imagining Possibilities with GenAI</b>
        <br />
        <br />
        EY Techathon 4.0 is open to undergraduate and postgraduate students (part-time or full-time) from any discipline in any college based out of India. Participants can register individually or in teams with at most 5 members. Cross-specialization and cross-college teams are allowed.
      </div>
    ),
    // problem: (
    //   <div>
    //     <li>
    //     What's in it for students?
    //     </li>
    //     <li>→ Global ranking to top coders</li>
    //     <li>→ Top 3 coders to win total a prize money of USD 20,000/-</li>
    //     <li>→ A chance to explore exciting careers* with one of the world's most powerful brand</li>
    //     <li>→ A chance to compete with some of the best coders in the world</li>
    //     <li>→ A platform to showcase your programming skills</li>
    //     <li>→ The finalists stand a chance to travel** to India for the live grand finale experience.</li>
    //   </div>
    // ),
    eligibility: (
      <div>
        <h2>Eligibility Criteria</h2>
        <p>
          Eligibility for Participants: The following eligibility criteria shall be applicable for participation
          in the Competition:
          <br />
          <br />
        </p>
        <li>
          • The Participant(s) should be an Indian citizen, residing in India only and above 18 years of age on
          the date of the participation
        </li>
        <li>
          • The Participant(s) should be students currently enrolled in a degree/diploma course (part-time or
          full-time) from any discipline in any college based out of India
        </li>
        <li>
          • The Participant(s) can participate either in their individual capacity or as a team with team size not
          exceeding 4 members at a time
        </li>
        <li>
          • The Participant(s) can have maximum of two non-students in their team provided that the students
          form the majority of the team composition
        </li>
        <li>
          • The Participant should not have a criminal conviction or an arrangement or a contract that prevents
          the Participant from participating in the Competition;
        </li>
        <li>
          • The Participant should have proof of age and address proof when requested by EY and must be
          mandatorily submitted by the Participant to EY, as may be required by EY to confirm the eligibility
          of the Participant;
        </li>
        <li>
          • The Participant should abide by all the applicable laws at all times, including complying with the
          lockdown order and other orders, instructions and guidelines issued by any government authority,
          judicial or quasi-judicial authority;
        </li>
        <li>
          • Employees, directors, partners of EY and its affiliates are not eligible to participate in this
          Competition;
        </li>
        <li>
          • The Participant(s) should be an Indian citizen, residing in India only and above 18 years of age on
          the date of the participation
        </li>
        <li>
          • In the event the Participant fulfils all the criteria above, then he/she/they may submit the Entries in
          accordance with these Terms and Conditions. EY reserves the right but not the obligation to verify
          if an individual meets all the criteria. EY reserves the sole right to disqualify and reject the Entries
          and registration of Participant(s) at any point of time in the event the above criteria or any part
          thereof is not met with or any discrepancy is found to exist in the information furnished/provided
          by the Participant at any point of time
        </li>
      </div>
    ),
    process: (
      <div>
        <h2> How do I register and login for the contest?</h2>
        <br />
        <br />
        <li>
          • The Participant(s) who are desirous of entering the Competition are required to register at
          www.ey.com/en_in/techathon-3 (“Microsite”) indicating the personal details of the individual or
          ofeach team member.
        </li>
        <li>
          • Once the Participant(s) accesses the Microsite, the Participant(s) shall be required to share the
          Personal Information by duly filling the personal details including but not limited to full name, age,
          city, college id, email ID, mobile phone number(s) and/or any other information/documentation as
          sought by EY, at its discretion (“Personal Information”) in a manner and form, as requested and
          /or prescribed on the Microsite
        </li>
        <li>• For avoidance of doubt, for the purposes of participating in the Competition set out hereinabove,
          registering for the Competition through a valid mobile phone number and email id via the medium
          of the Microsite is mandatory for all interested Participants. EY is not responsible to validate
          correctness of the mobile number and email id provided by the Participant(s)</li>
        <li>• Then choose India or Rest of the World, as applicable</li>
        <li>
          • If at any point of time EY finds that the mobile phone number, email id or any other information
          provided by the Participant(s) including on the Microsite, is false and/or unauthenticated, then EY
          shall have the right to forthwith disqualify such Participant from further participation and his/her
          Project shall immediately be considered to be null and void
        </li>
        <li>
          • The interested Participant(s) shall carefully read the Terms and Conditions, complete filling details
          of the Personal Information and click the checkbox 'I Accept Terms & Conditions' in order to
          accept the Terms and Conditions and submission of the Personal Information.
        </li>
        <li>
          • The Participant(s) agrees and undertakes that all Personal Information, provided by them at the
          time of registration is true, correct, and accurate.
        </li>
        <br />
        <br />
        <b> Round 1(Executive Summary Submission):</b>
        <br />
        <br />
        <ul>
          <li>• Only registered students will be able to access the problem statements from the round active.</li>
          <li>
            • Teams will be required to submit a 1-3 slider PPT (.pdf format) of their proposed solution in the prescribed format.
          </li>
          <li>
            • The submission templates can be accessed by the registered students/teams from the round titled "Round 1: Executive Summary Submission" on this page.
          </li>
          <li>
            • All the participants are requested to submit their solutions well before the deadline to avoid any last-minute hassle.
          </li>
          <li>
            • Based on the submissions received, the top teams will be shortlisted and taken to the next round.
          </li>
        </ul>
        <br />
        <br />
        <ul>
          <li>
            <b>Round 2 (Semi-Final: Detailed Presentation Submission):</b>
            <br />• The shortlisted teams from Round 1 will upload their detailed presentation on the chosen problem statement.
            <br />• Teams will be required to submit their detailed presentation in a deck with less than or equal to 10 slides.
            <br />• The presentation should be structured and based on the presentation duration i.e. 10 minutes.

          </li>
          <br />
          <li>
            <b>Semi Finale Presentations:</b>
            <br />
            • Shortlisted participants will present their solution models in this round to a jury panel comprising EY leaders. Based on this round, finalists will be announced.
          </li>
          <br />
          <li>
            <b>Mentorship:</b>
            <br />
            • The shortlisted teams will work with their respective mentors to prepare their innovative submissions for the Grand Final.
            <br />• This is a great opportunity for teams to get some expert guidance on any roadblocks they may be facing or simply to just gain some confidence by pitching EY leaders.
          </li>
          <br />
          <li>
            <b>Round 3 ( Grand Finale):</b>
            <br />
            • The Gen AI use cases will be presented by the finalists to a jury comprising industry leaders in the grand finale and the winners will be announced.
          </li>
          <br />
          <br />
        </ul>
      </div>
    ),
    judgingCriteria: (
      <div>
        <h3>The Projects shall be evaluated and shortlisted by a Screening Jury based on the following criteria:</h3>
        <ul>
          <li>→ Uniqueness of the idea</li>
          <li>→ Design of the solution</li>
          <li>→ Solution design framework and use of technology</li>
          <li>→ End-user benefit and social impact</li>
          <li>→ Based on the scores received from the Screening Jury, some Projects shall be shortlisted for
            evaluation by the Semi-Finals and Grand Finale Jury</li>
          <li>→ The semi-finalists and finalists will then be required to make a virtual presentation to the SemiFinals and Grand Finale
            Jury post which the winners shall be decided based on the scores provided
            by the Final Jury ('Winner')</li>
          <li>→ The Jury shall have the sole authority to judge and evaluate the Projects and their decision shall be
            final and binding on all Participants</li>
        </ul>
      </div>
    ),
    reward: (
      <div>
        <li>→ Winner - Prizes worth INR 3,00,000</li>
        <li>→ Runner-Up - Prizes worth INR 2,00,000</li>
        <li>
          → Exciting cash prizes up for grabs.
        </li>
        <li>→ Internship opportunities with EY.</li>
        <li>→ Mentoring with EY and industry stalwarts.</li>
      </div>
    ),
  },
  {
    id: 7,
    company: "CyientifIQ Innovation League- Global Hackathon",
    cover: IMG7,
    about: (
      <div>
        <b>COMMENCEMENT AND DURATION OF THE HACKATHON</b>
        <br />
        <br />
        Ideation(Online)-  Sep 16,2023, 12:00 PM EDT - Oct 15,2023, 08:00 PM EDT<br />
        Development(Online)-  Oct 23,2023, 01:00 PM EDT - Nov 19,2023, 08:00 PM EST
      </div>
    ),
    problem: (
      <div>
        <li>→ Designing Digital Enterprises</li><br />
        <li>→ Building Intelligent Products and Platforms</li><br />
        <li>→ Solving Sustainability Challenges</li>
      </div>
    ),
    process: (
      <div>
        <b>Eligibility Criteria:</b>
        <br />
        <br />
        <ul>
          <li>
            • The participants will have to abide by the official rules provided.There is no entrance fee for participation.
          </li>
          <li>• This is an online hackathon, and you may participate from anywhere.</li>

        </ul>
        <br />
        <br />
        <b>Rules:</b>
        <br />
        <br />
        <ul>
          <li>
            • Registration is open for both individuals and teams of up to five (5) members. If you would like to join a team, you have two options available: invite friends to form your team or browse registered participants (located in the Teams Tab) and request to join a team.
          </li>
          <br />
          <li>
            • *Once the Ideation phase ends, you may not join another team
          </li>
          <br />
          <li>
            •The hackathon will be held online. The hackathon Ideation phase starts on September 16, 2023, at 9 am PDT and ends on October 15, 2023, at 5 pm PDT. Judging Ideation phase with an announcement on October 23, 2023, at 9 am PDT on teams that will move forward to the development phase. The development phase starts on October 23, 2023, at 9 am PDT and ends on November 19, 2023, at 5 pm PDT. Winners will be announced on December 7, 2023, at 9 am PDT.<br />
            In the Ideation phase, your team will be required to submit an idea as specified for this phase. The submissions will undergo evaluation, and to progress to the Development phase, your team must attain a score of 70 points or higher. This score will determine your eligibility to proceed to the next phase.

          </li>
          <br />
          <li>
            • There are 3 themes for the hackathon, and participants must submit a hack that falls under one of these themes.
          </li>
          <br />
          <li>
            • Participants can submit their hack as many times as they want, and the last hack will be considered as the final submission.
          </li>
          <br />
          <li>
            • Participants must come up with new and innovative ideas, and any copied idea will be disqualified.
          </li>
          <br />
          <li>
            • The hack must be developed entirely during the Hackathon duration.
          </li>
          <br />
          <li>
            • Participants can use freely available systems and open-source libraries.
          </li>
          <br />
          <li>
            • Participants must agree to abide by the terms and conditions of HackerEarth and Cyient Official Rules to participate in the hackathon.
          </li>
          <br />

        </ul>
      </div>
    ),
    judgingCriteria: (
      <div>
        <b>Ideation phase:-</b>
        <br />
        <ul>
          <li>→ Applicability</li>
          <li>→ Uniqueness</li>
          <li>→ Feasibility</li>
          <li>→ Clarity of Thought</li>
          <li>→ Value and Relevance</li>
        </ul>
        <br />
        <b>Development phase:-</b>
        <br />
        <ul>
          <li>→ Functionality</li>
          <li>→ Innovation</li>
          <li>→ User Experience</li>
          <li>→ Code Quality</li>
          <li>→ Technical Difficulty</li>
          <li>→ Scalability</li>
          <li>→ Presentation and Communication</li>
          <li>→ Impact and Usefulness</li>
        </ul>
      </div>
    ),
    reward: (
      <div>
        <ul>
          <li>
            • <b>High Potential (USD 3000):</b><br />
            The prize will be awarded to the team or individual whose solution demonstrates the greatest potential for success and impact.

          </li>
          <li>
            • <b>CYIENTIFIQ Value innovation Prize (1 per theme) (3) (USD 1000)</b><br />
            The value innovation prize wil be provided to an outstanding team or individual in each theme who achieves the highest level of innovation and excellence.
          </li>
          <li>
            • <b>Grand Prize (USD 10000)</b><br />
            The grand prize is reserved for the ultimate champion of CyientifIQ Innovation League.
          </li>
          <li>
            • <b>CYIENTIFIQ Champion (USD 5000)</b><br />
            One dedicated prize honouring the team or individual whose overall solution demonstrates unparalleled originality, uniqueness, and visionary thinking.The grand prize is reserved for the ultimate champion of CyientifIQ Innovation League.
          </li>
        </ul>
      </div>
    ),
  },
  {
    id: 8,
    company: "Codegeist Unleashed 2023 ",
    cover: IMG8,
    about: (
      <div>
        <b>  ABOUT </b>
        <br />
        <br />
        Gear up for Codegeist Unleashed - an exciting twist on Atlassian’s annual hackathon. This year, developers get a shot at winning cash prizes, PLUS the chance to compete live in Amsterdam at Atlassian Presents: Unleash and show off their AI building skills.
        First place winners in each category will have the unique opportunity to live demo their app in front of Atlassian customers, rub elbows with the Atlassian team and take home an additional cash prize! Get ready to learn new skills on Atlassian's Forge platform and fuel the future of the workplace with AI.
      </div>
    ),
    problem: (
      <div>

        <b> Codegeist Unleashed 2023 </b>
        <br />
        <br />
        <li>Learn about AI tools and the Atlassian Forge platform</li>
        <li>Get creative with new ways to use AI to improve workflows</li>
        <li>Make connections within the Atlassian global developer community</li>
        <li>Compete for $172,500 in prizes and trips to Amsterdam for the top 3 winners </li>
      </div>
    ),
    process: (
      <div>
        <b> Who should Participate</b>
        <br />
        <br />
        <li>
          • Whether you're considering joining as a solo developer or assembling a team – consider this your formal invite. Still not sure if this is the right hackathon for you?
          <ul>
            <li>Developers</li>
            <li>Startup founders or entrepreneurs</li>
            <li>Designers</li>
            <li>Students (high school to postgraduate)</li>
            <li>Project Managers</li>
            <li>Systems analyst</li>
            <li>Business analyst</li>
            <li>Jira and Confluence experts</li>
          </ul>
        </li>
      </div>
    ),
    eligiblity: (
      <div>
        <b>REQUIREMENTS</b>
        <br />
        <br />
        <em>What to build</em><br />
        Build an AI app using Atlassian’s Forge platform in one of the following categories:<br />
        <em>AI Apps for Developer Experience:</em><br />
        Enable more efficient and seamless developer workflows by automating repetitive work and forecasting next actions. <br />
        <em>AI Apps for Collaboration:</em><br />
        Foster thoughtful and innovative collaboration across individuals, teams, and tools. <br />
        <em>AI Apps for Data-Driven Insights</em><br />
        Utilize data to make predictive recommendations that unearth useful and unexpected insights.<br />
      </div>
    ),
    rounds: (
      <div>
        <b>ROUNDS</b>
        <br />
        <br />
        <b>Codegeist Unleashed 2023 has two rounds:</b>

        Qualifying Round: This round is open to all participants and takes place online from October 7th to October 20th, 2023. Participants will have 72 hours to complete a series of coding challenges and submit their solutions. The top 100 participants in each of the three categories will advance to the next round.<br />
        Finals: The finals will take place live in Amsterdam at Atlassian Presents: Unleash on November 17th, 2023. The finalists will have 24 hours to develop and pitch their apps to a panel of judges. The winners will be announced at the end of the event.
        Categories<br />

        The three categories for Codegeist Unleashed 2023 are:
        <br />

        Forge: Build an app using Atlassian's Forge platform.
        AI: Build an app that uses AI to improve the workplace.
        Open: Build any app that you can think of that improves the workplace.
      </div>
    ),
    prizes: (
      <div>
        <b>Prizes</b>
        <br />
        <br />
        The total prize pool for Codegeist Unleashed 2023 is $172,500. The winners in each category will receive the following prizes:
        <li>
          1st place: $10,000
          2nd place: $5,000
          3rd place: $2,500
        </li>
      </div>
    ),
    judging: (
      <div>
        <b>JUDGING CRITERIA</b>
        <br />
        <br />
        The judging criteria for Codegeist Unleashed 2023 are as follows:
        <li>
          Quality of the Idea: How original and creative is the app's idea? Does it solve a real-world problem? Is it well-conceived and thought-out?
          Implementation of the Idea: How well is the app implemented? Is it easy to use and navigate? Is it well-coded and efficient?
          Potential Impact: How much potential does the app have to impact the workplace? Could it be used by a large number of people? Does it have the potential to make a real difference?
          Most Valuable Storytelling: How well is the app's story told? Does the pitch clearly and concisely explain the app's purpose and benefits? Is the pitch engaging and persuasive?
          Responsible AI: How well does the app demonstrate responsible implementation of AI? Does it preserve privacy? Is it transparent? Does it create experiences that inspire trust and adherence to security best practices?
        </li>
        <br />
        <b>Deadline: Oct 24, 2023 @ 11:45pm </b>
      </div>
    )
  },
  {
    id: 10,
    company: "DevFest Eket 2023 – Ecosolutions",
    cover: IMG10,
    about: (
      <div>
        <b> DevFest Eket 2023 – Ecosolutions for Students</b>
        <br />
        <br />
        <p>
          The "DevFest Eket 2023 - Ecosolutions" hackathon at DevFest Eket 2023 focuses on addressing environmental and sustainability challenges specific to the Eket region. Participants are challenged to develop innovative tech solutions that can contribute to a more sustainable and eco-friendly future for Eket and its surroundings.
        </p>
      </div>
    ),
    process: (
      <div>
        <h2> How do I register and login for the contest?</h2>
        <p>
          Registration process is common for India and Global participants.
          Steps are as enumerated below:
        </p>
        <ul>
          <li>
            • Join the hackathon
          </li>
          <li>
            • Register for DevFest Eket event here https://bit.ly/devfesteket23 
          </li>
          <li>• Form a team. Maximum 5 members</li>
          <li>•Join discord channel https://discord.gg/bvpqUMyVjc </li>
          <li>
            • Pick a challenge from the list or challenges above or any outside the challenge list and  build out a Minimum Viable Product (MVP) to solve that problem. It can be a web or mobile or desktop app ocxr a productivity framework, so long as it adds value to the chosen sector.
          </li>
          <li>
            • Make a minimum one minute video of the problem your team is trying to solve, send to the organizers, your video will be uploaded on our social media handles. You will invite people to link our page and like and share your team video.
          </li>
        </ul>
        <p>
          You are to build a web or mobile or desktop app ocxr a productivity framework that will help solve the challenges in any of the following domain
        </p>
        <ul>
          <li>
            • Clean Energy Solutions: Develop sustainable and renewable energy solutions tailored to the Eket region, whether it's solar, wind, or other clean energy sources.
          </li>
          <li>
            •Waste Management Innovation: Create applications or devices that improve waste management processes, recycling, or waste-to-energy conversion within Eket.
          </li>
          <li>
            •Smart Agriculture for Local Farmers: Build tech solutions that empower local farmers with data-driven insights, weather forecasts, and precision agriculture tools to enhance crop yields sustainably.
          </li>
          <li>
            •Other domain
          </li>
        </ul>
        <p>
          What to submit?
        </p>
        <ul>
          <li>
            • A pitch deck explaining your project.
          </li>
          <li>
            • Demo: You will demo and show the judges your project on the DevFest day. 
          </li>
          <li>
            • Open Source Code: Your GitHub project code repository must include an open source license. Provide a link to your open source repository in your submission.
          </li>
          <li>
            • Write up. Be sure to include a description of your project. Explain why you built your project and how it benefits the sector
          </li>
          <li>
            • Top 3 Rankers will get a chance to win USD 20,000.
          </li>
        </ul>
      </div>
    ),
    judgingCriteria: (
      <div>
        <ul>
          <li>→ Technical Proficiency (30%)</li>
          <li>→ Innovation and Creativity (20%)</li>
          <li>→ Impact and sustainability (20%)</li>
          <li>→ User Experience (UX) and Accessibility(15%)</li>
          <li>→ Presentation and Pitch (15%)</li>
        </ul>
      </div>
    ),
    benefits: (
      <div>
        <ul>
          <li>● It gives you an opportunity to brand your organization nationally.</li>
          <li>● It gives recognition and visibility for your organization across all technical institutions in India.</li>
          <li>● The young techies from all over the country offer out-of-the-box solutions to your problems.</li>
          <li>● It provides an opportunity to be a part of world’s biggest open innovation movement.</li>
          <li>● It gives an opportunity to work with some of the best talent in the country.</li>
          <li>● Technology students across India compete to creatively solve problems and offer technical solutions.</li>
        </ul>
      </div>
    ),
    reward: (
      <div>
        <ul>
          <li>1st Position - N50,000.00 + DevFest Swag</li>
          <li>2nd Position - N30,000.00 + DevFest Swag</li>
          <li>3rd Position - N20,000.00 + DevFest Swag</li>
        </ul>
      </div>
    ),
  },
  {
    id: 9,
    company: "ICON Hyperbuild",
    cover: IMG9,
    about: (
      <div>
        <b>It is an online global hackathon that aims to build cross-chain solutions to expand the existing ICON cross-chain ecosystem</b>
        <br />
        <br />
        The hackathon has no fixed number of rounds, but the submissions will be evaluated by the judges 
        <br />
        Submission Period: January 26th, 2023 – March 16th 2023
        <br />
        The winners will be announced on March 27, 2023
      </div>
    ),
    problem: (
      <div>
        <li>→ The hackathon website says that you can work on any problem that can be solved by cross-chain interoperability, such as decentralized finance, gaming, social media, identity, etc. You can also use any other blockchain platform that supports BTP (Blockchain Transmission Protocol) or xCall (Cross-Chain Call) to connect with ICON2.</li>
        <li>→ Some examples of possible problems are:</li>
        <li>→ Creating a cross-chain bridge for token swaps between different blockchains</li>
        <li>→ Building a decentralized exchange that supports multiple assets and liquidity pools</li>
        <li>→ Developing a game that integrates NFTs from different blockchains</li>
        <li>→ Implementing a social media platform that rewards users with tokens from different blockchains</li>
        <li>→ Designing a digital identity system that verifies credentials across different blockchains</li>
      </div>
    ),
    process: (
      <div>
        <b>Note:</b>
        <br />
        <br />
        <ul>
          <li>
            •<i> Join ICON's Official Discord to find teammates, interact with fellow ICON developers, receive ICON development help and get all your technical questions answered.</i>
          </li>
          <li>
            •<i>Get all your ICON development resources at ICON Developer Portal.</i>
          </li>
          <li>
            • <i>Public Voting will contribute in determining the hackathon winners. Share your project on social media and gather as much +1 community support as possible.</i>
          </li>
          <li>
            • <i>All prizes are awarded in ICX, ICON's native token, in the equivalent USD value.</i>
          </li>
        </ul>
        <br />
        <br />
        <b>Eligibility Criteria:</b>
        <br />
        <br />
        <ul>
          <li> <b>•Idea:</b> We focus on simple but unique ideas that approach prospective solutions to expand the ICON 2.0 ecosystem and how well this is presented </li>
          <li> <b>•Implementation:</b> How the idea is implemented. How good is the overall user experience? Is it easy to use? Is the design appealing to end users?
          </li>
          <li>
          <b>•	Development: </b>How much of the ICON protocol is being interfaced with or utilized via SCORE? How useful is the blockchain implementation of the project?

          </li>
          <li>
          <b>•	Interoperability: </b>Does the project contribute to ICON's interoperable future by utilizing ICON Project interoperability features such as BTP or ICON Bridge technology?

          </li>
          <li> <b>•	Value:</b> Is the project able to be developed continuously in a sustainable fashion?
          </li>
        </ul>
        <br />
        <br />
        <b>Requirements:</b>
        <br />
        <br />
        <ul>
          <li>Cross-chain Infrastructure</li>
          <li>Metaverse</li>
          <li>Social Impact / Public Good</li>
          <li>NFT & Gaming</li>
          <li>Utility</li>
        </ul>
      </div>
    ),
    reward: (
      <div>
        <ul>
          <li>
            $20,000 USD in ICX
          </li>
          <li>
            $10,000 USD in ICX
          </li>
          <li>
            $5,000 USD in ICX
          </li>
          <li>
            Category Prize - Tooling & Infrastructure:
            <ul>
              <li>$700 USD in ICX</li>
            </ul>
          </li>
          <li>
            Category Prize - Social Impact / Public Good:
            <ul>
              <li>$700 USD in ICX</li>
            </ul>
          </li>
          <li>
            Category Prize - NFT & Gaming:
            <ul>
              <li>$700 USD in ICX</li>
            </ul>
          </li>
        </ul>
      </div>
    ),
  }
];
