
export const teamMentors = [
    {        
        name: 'Mr. Saksham Dubey',
        title: 'Technical Department',
        img: ''
    },
   {        
        name: 'Ms. Anshita Yadav',
        title: 'Content Department',
        img: ''
    },
   {        
        name: 'Ms. Chanchal Malik',
        title: 'Content Department',
        img: ''
    },
{        
        name: 'Mr. Akshat Srivastava',
        title: 'Outreach Department',
        img: ''
    },
{        
        name: 'Mr. Ankit Drabu',
        title: 'Outreach Department',
        img: ''
    },
]