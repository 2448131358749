import amar from "../../../assets/team/amar.jpg";
import anshika from "../../../assets/team/anshikach.JPG";
import aryan from "../../../assets/team/aryanporwal.jpg";
import ayushT from "../../../assets/team/Ayush Tyagi.jpg";
import ayush from "../../../assets/team/ayush.jpeg";
import bhoomi from "../../../assets/team/bhoomi.jpg";
import darshika from "../../../assets/team/darshika.jpeg";
import faraz from "../../../assets/team/Faraz Hafeez.jpg";
import gaurav from "../../../assets/team/gauravgulati.jpeg";
import harshit from "../../../assets/team/Harshit Singh Mahara.jpg";
import injila from "../../../assets/team/injila.jpg";
import ish from "../../../assets/team/ish.jpg";
import kavya from "../../../assets/team/Kavya Tyagi.jpg";
import keshav from "../../../assets/team/keshavjha.jpeg";
import khushi from "../../../assets/team/kushi.jpeg";
import Naman from "../../../assets/team/Naman Goel.jpeg";
import parth from "../../../assets/team/Parth Singhal.jpg";
import poras from "../../../assets/team/poras.jpeg";
import saurabh from "../../../assets/team/saurabhsrivastava.jpg";
import shrey from "../../../assets/team/shreyvashistha.jpg";
import Tanisha from "../../../assets/team/tanisha.jpg";
import tanya from "../../../assets/team/Tanya.jpg";
import vivek from "../../../assets/team/vivek.jpeg";

export const mentors = [
  {
    name: "Mr.Aryan Porwal",
    img: aryan,
  },
  {
    name: "Mr.Ayush Tyagi",
    img: ayushT,
  },
  {
    name: "Ms.Darshika Gupta",
    img: darshika,
  },
  {
    name: "Mr.Poras Saini",
    img: poras,
  },
  {
    name: "Ms.Anshika Chauhan",
    img: anshika,
  },
  {
    name: "Mr.Amar Jyoti",
    img: amar,
  },
  {
    name: "Ms.Bhoomi",
    img: bhoomi,
  },
  {
    name: "Mr.Saurabh Srivastava",
    img: saurabh,
  },
  {
    name: "Mr.Keshav Jha",
    img: keshav,
  },
  {
    name: "Ms.Injila Ali",
    img: injila,
  },
  {
    name: "Mr.Gaurav Gulati",
    img: gaurav,
  },
  {
    name: "Mr.Shrey Vashistha",
    img: shrey,
  },

  {
    name: "Mr.Ayush Malik",
    img: ayush,
  },
  {
    name: "Mr. Naman Goel",
    img: Naman
  },
  {
    name: "Mr. Parth Singhal",
    img: parth
  },
  {
    name: "Ms. Kavya Tyagi",
    img: kavya
  },
  {
    name: "Mr. Harshit Singh Mahara",
    img: harshit
  },
  {
    name: "Ms. Tanisha Bhatt",
    img: Tanisha
  },
  {
    name: "Ms. Tanya Srivastava",
    img: tanya
  },
  {
    name: "Mr. Faraz Hafeez",
    img: faraz
  },
  {
    name: "Ms. Khushi Chaudhary",
    img: khushi
  },
  {
    name: "Mr. Ish Purwar",
    img: ish
  },
  {
    name: "Mr. Vivek Kumar Tiwari",
    img: vivek
  },
];
