import React from "react";
import "../styles/Loader.css";

const Loader = () => {
  return (
    <div class="loader">
      <span>S</span>
      <span>A</span>
      <span>L</span>
      <span>A</span>
      <span>A</span>
      <span>H.</span>
    </div>
  );
};

export default Loader;
